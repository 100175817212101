import { type ReactElement } from 'react'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import { type DirectoryCardGroupDirectoryCards } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { Card, CardBody } from '@stuller/stullercom/ui'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackDirectoryLink } from './ContentstackDirectoryLink'
import Link from 'next/link'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackDirectoryCardProps {
  data?: DirectoryCardGroupDirectoryCards | null
  columnClass: string
}

/**
 * Individual card inside a Directory Card Group
 */
function ContentstackDirectoryCard ({ data, columnClass }: ContentstackDirectoryCardProps): ReactElement | null {
  const {
    title,
    targetingConnection,
    header,
    body,
    featured_image: featuredImage,
    directory_links: directoryLinks,
    view_all_link: viewAllLink
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  const isHeadingEmpty = isRteEmpty(header?.heading?.json)
  const isBodyEmpty = isRteEmpty(body?.rich_text)
  const isFeaturedImageEmpty = isSimpleImageEmpty(featuredImage)
  if (!showContent) {
    return null
  }

  return (
    <div className={columnClass} data-gtm-click-section='card' data-gtm-click-subsection1={title}>
      <Card>
        {!isFeaturedImageEmpty && (
          <div style={{ height: 220 }}>
            <ContentstackSimpleImage className='object-fit-contain h-100 m-auto d-block' data={featuredImage} />
          </div>
        )}
        <CardBody className='p-4'>
          <div className='row gy-4'>
            {!isHeadingEmpty && (
              <div className='col-12 mb-n3'>
                <h2><ContentstackJsonRte excludeOuterTags json={header?.heading?.json} /></h2>
              </div>
            )}
            {!isBodyEmpty && (
              <div className='col-12'>
                <ContentstackJsonRte excludeOuterTags json={body?.rich_text?.json} />
              </div>
            )}
          </div>
          {directoryLinks?.map((data, index) => (
            <ContentstackDirectoryLink key={index} data={data} />
          ))}
          {!isStringEmpty(viewAllLink?.view_all_link?.href) != null && (
            <div className='row'>
              <div className='col d-flex align-items-center justify-content-between py-4 pb-0'>
                <Link href={viewAllLink?.view_all_link?.href ?? ''}>
                  <ContentstackJsonRte json={viewAllLink?.view_all_link_text?.json} />
                </Link>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export {
  ContentstackDirectoryCard
}
