import { type ReactElement } from 'react'
import { ModalBody } from '@stuller/stullercom/ui'
import { type ContentstackModalProps } from './ContentstackModal'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import clsx from 'clsx'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { ContentstackEscapeHatch } from '../contentstack-escape-hatch/ContentstackEscapeHatch'
import { ContentstackLegacyCmsContent } from '../contentstack-legacy-cms-content/ContentstackLegacyCmsContent'

/**
 * Main content of modal
 */
function ContentstackModalBody ({ data }: ContentstackModalProps): ReactElement | null {
  const { body } = data ?? {}

  if (isArrayEmpty(body)) {
    return null
  }

  return (
    <ModalBody>
      {body?.map((component, key) => {
        const verticalMarginClassNames = clsx(key === body?.length - 1 ? 'mt-3' : 'my-3')

        switch (component?.__typename) {
          case 'ModalBodyImage': {
            return !isSimpleImageEmpty(component.image) && (
              <div className={verticalMarginClassNames} key={key}>
                <ContentstackSimpleImage data={component.image} />
              </div>
            )
          }

          case 'ModalBodyRichText': {
            return !isRteEmpty(component.rich_text?.rich_text?.json) && (
              <div className={verticalMarginClassNames} key={key}>
                <ContentstackJsonRte json={component.rich_text?.rich_text?.json} />
              </div>
            )
          }

          case 'ModalBodyVideo': {
            return !isYoutubeVideoEmpty(component.video) && (
              <div className={verticalMarginClassNames} key={key}>
                <ContentstackYoutubeVideo data={component.video} maxWidth='100%' />
              </div>
            )
          }

          case 'ModalBodyEscapeHatch': {
            return (
              <div className={verticalMarginClassNames} key={key}>
                <ContentstackEscapeHatch data={component.escape_hatch?.escape_hatchConnection?.edges?.[0]?.node} />
              </div>
            )
          }

          case 'ModalBodyLegacyCmsContent': {
            return (
              <div className={verticalMarginClassNames} key={key}>
                <ContentstackLegacyCmsContent
                  key={key}
                  data={component.legacy_cms_content?.legacy_cms_contentConnection?.edges?.[0]?.node}
                />
              </div>
            )
          }

          default: {
            return null
          }
        }
      })}
    </ModalBody>
  )
}

export {
  ContentstackModalBody
}
