import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { ContentstackSocialIcon } from './ContentstackSocialIcon'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type ReactElement } from 'react'
import { type SocialIconsFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackSocialIconsFeatureProps {
  data?: SocialIconsFeatureInfoFragment | null
}

/**
 * The Social Icons Feature provides links to Stuller’s social media channels.
 *
 * It gives users the ability to directly engage with the brand and its followers.
 */
function ContentstackSocialIconsFeature ({ data }: ContentstackSocialIconsFeatureProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    body,
    social_media_icons: socialMediaIcons,
    appearance,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (sectionHeader == null && body == null && socialMediaIcons == null) {
    return null
  }

  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)
  const textColorClassName = appearance?.icon_color === 'blue-500' ? 'text-white' : 'text-body'

  const sectionClassName = clsx(
    appearance?.icon_color === 'blue-500' && 'bg-blue-500'
  )

  const textClassName = clsx(
    'text-center justify-content-center mb-0',
    textColorClassName
  )

  return (
    <section
      className={sectionClassName}
      id={contentstackTitleToHtmlId(title)}
      data-gtm-click-section='icon'
      data-gtm-click-subsection1={title}
    >
      <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
        <div className='row flex-column flex-md-row gy-5'>

          <ContentstackSectionHeader data={sectionHeader} title={title} />

          <div className='col-12'>
            <div className='row gy-3 justify-content-center'>
              {socialMediaIcons?.map((socialMediaIcon, key) => (
                <ContentstackSocialIcon
                  socialMediaIcon={socialMediaIcon}
                  iconColor={appearance?.icon_color ?? 'blue-500'}
                  key={key}
                  index={key}
                />))}
            </div>
          </div>

          {!isBodyEmpty && (
            <p className={textClassName}>
              <ContentstackJsonRte excludeOuterTags json={body?.rich_text?.json} />
            </p>)}

        </div>
      </div>
    </section>
  )
}

export {
  ContentstackSocialIconsFeature
}
