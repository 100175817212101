import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { ContentstackVerticalMediaBox } from './ContentstackVerticalMediaBox'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type ReactElement } from 'react'
import { type VerticalMediaBoxGroup } from './types'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackVerticalMediaBoxGroupProps {
  data?: VerticalMediaBoxGroup
}

const verticalMediaBoxColumns: Record<string, string> = {
  full: 'col-md-12',
  half: 'col-md-6',
  third: 'col-md-4',
  fourth: 'col-md-3'
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * A CMS user should be able to add a group of Vertical Media Boxes to a content page
 * The Vertical Media Box Group is a collection of containers that optionally include featured media, text, and buttons.
 */
function ContentstackVerticalMediaBoxGroup ({ data }: ContentstackVerticalMediaBoxGroupProps): ReactElement | null {
  const {
    title,
    appearance,
    button,
    section_header: sectionHeader,
    vertical_media_boxes: verticalMediaBoxes,
    targetingConnection
  } = data ?? {}

  const verticalMediaBoxCol = (appearance?.vertical_media_box_width != null && verticalMediaBoxColumns[appearance.vertical_media_box_width]) ?? ''

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(verticalMediaBoxes)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const emptyButton = isButtonEmpty(button)
  const containerClassNames = clsx(
    'container-xxxl mw-xxl py-6 py-md-8 px-4',
    spacing?.className
  )

  const buttonClassNames = clsx(
    'col-12 mt-6 d-flex',
    (appearance?.button_alignment != null && alignmentMap[appearance.button_alignment]) ?? 'justify-content-start'
  )

  return (
    <section className={background.className} id={contentstackTitleToHtmlId(title)}>
      <div className={containerClassNames}>
        <div className='row flex-column flex-md-row gy-6'>
          <ContentstackSectionHeader data={sectionHeader} title={title} />
          {verticalMediaBoxes?.map((verticalMediaBox, i) => (
            <ContentstackVerticalMediaBox
              key={i}
              data={verticalMediaBox}
              columnClass={verticalMediaBoxCol}
              alignmentClass={`text-${verticalMediaBox?.appearance?.alignment}`}
            />)
          )}
          {(!emptyButton) && (
            <div className={buttonClassNames} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
              <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
            </div>)}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackVerticalMediaBoxGroup
}
