import { type ReactElement } from 'react'
import { type TopicInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ProductBadge, type ProductBadgeType } from '@stuller/stullercom/ui'
import clsx from 'clsx'

interface ContentstackTopicHeaderProps {
  data: TopicInfoFragment['header']
  textPosition?: string
  headingFontSize?: string | null
}

/**
 * Renders the badge, heading, and subheading inside of a ContentstackTopic component
 * @memberof ContentstackTopic
 */
function ContentstackTopicHeader ({ data, textPosition, headingFontSize }: ContentstackTopicHeaderProps): ReactElement | null {
  const {
    heading,
    heading_image: headingImage,
    subheading,
    badge
  } = data ?? {}
  const textPositionClasses = clsx(textPosition === 'center' && 'text-center')

  const headingEmpty = isRteEmpty(heading?.json)
  const subheadingEmpty = isRteEmpty(subheading?.json)
  const headingImageEmpty = isSimpleImageEmpty(headingImage)

  if (headingEmpty && subheadingEmpty && headingImageEmpty && badge == null) {
    return null
  }

  const headingClassNames = clsx(
    'mb-2',
    headingFontSize ?? ''
  )

  return (
    <div className={textPositionClasses}>
      {!headingImageEmpty && <div className='mb-3'><ContentstackSimpleImage data={headingImage} /></div>}
      {badge != null && <div className='mb-3'><ProductBadge type={badge as ProductBadgeType} /></div>}
      {!headingEmpty && <h2 className={headingClassNames}><ContentstackJsonRte excludeOuterTags json={heading?.json} /></h2>}
      {!subheadingEmpty && <h3><ContentstackJsonRte excludeOuterTags json={subheading?.json} /></h3>}
    </div>
  )
}

export {
  ContentstackTopicHeader
}
