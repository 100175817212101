import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

function useBillToAccountTargeting (targeting: TargetingMap['BILL_TO_ACCOUNT']): boolean {
  const { authUser } = useAuth()
  const billToId = authUser?.user?.defaultActiveShipToAccount?.billToAccount?.id

  return billToId == null ? false : targeting.targetValue.includes(billToId)
}

export { useBillToAccountTargeting }
