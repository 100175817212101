import { Button, Form, FormLabel, Input, InputGroup } from '@stuller/stullercom/ui'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type ReactElement, useState } from 'react'
import { type SearchBySerialNumberInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { isStringEmpty, stringToHtmlId } from '@stuller/shared/util/core'
import { trackUniversalEvent } from '@stuller/stullercom/feat/google-tag-manager'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackSearchBySerialNumberProps {
  data?: SearchBySerialNumberInfoFragment | null
}

/**
 * Renders a text input field that opens the results for a serial number in a new tab
 */
function ContentstackSearchBySerialNumber ({ data }: ContentstackSearchBySerialNumberProps): ReactElement | null {
  const sectionClasses = clsx(
    'px-4 py-8',
    data?.appearance?.background != null && `bg-${data?.appearance?.background}`
  )
  const [serialNumber, setSerialNumber] = useState<string>('')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(data?.targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || data == null) {
    return null
  }

  function handleSerialNumberSearchChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setSerialNumber(e.target.value)
  }

  function handleSearch (): void {
    if (!isStringEmpty(serialNumber)) {
      trackUniversalEvent({
        category: 'CategoryPage',
        action: 'SearchBar',
        label: 'SearchBySerial'
      })
      window.open(`/serializedproduct/${serialNumber}?recommendationSource=SearchBySerialNumber`)
    }
  }

  return (
    <section className={sectionClasses} id={stringToHtmlId(data?.title, 'section-')}>
      <div className='container-xxxl mw-xxl px-4'>
        <div className='row align-items-md-center gy-3 justify-content-center flex-column flex-md-row gx-4'>
          <div className='col-auto'>
            <h3 className='mb-0'>Search by Serial #</h3>
          </div>
          <div className='col-12 col-md-4'>
            <Form onSubmit={handleSearch}>
              <FormLabel
                htmlFor='searchBySerialNumber'
                className='sr-only'
              >
                Enter a Serial Number
              </FormLabel>
              <InputGroup size='lg'>
                <Input
                  type='text'
                  maxLength={8}
                  id='searchBySerialNumber'
                  placeholder='Enter a Serial #'
                  onChange={handleSerialNumberSearchChange}
                  pattern='^[0-9]*$'
                  required
                />
                <Button
                  type='submit'
                  aria-label='Search'
                  role='button'
                >
                  <FontAwesomeIcon className='px-2' icon={fas.faSearch} />
                </Button>
              </InputGroup>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}

export {
  ContentstackSearchBySerialNumber
}
