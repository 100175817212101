import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { ContentstackTopicBody } from './ContentstackTopicBody'
import { ContentstackTopicFeaturedMedia } from './ContentstackTopicFeaturedMedia'
import { ContentstackTopicHeader } from './ContentstackTopicHeader'
import { type ReactElement } from 'react'
import { type TopicInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackTopicProps {
  data?: TopicInfoFragment | null
}

const columnClasses: Record<string, string> = {
  half: 'col-md-6',
  'two-thirds': 'col-md-8'
}

/**
 * Component for rendering a Topic created in Contentstack
 */
function ContentstackTopic ({ data }: ContentstackTopicProps): ReactElement | null {
  const {
    title,
    appearance,
    header,
    featured_media: featuredMedia,
    body,
    targetingConnection
  } = data ?? {}

  const {
    text_color: textColor,
    text_position: textPosition,
    text_width: textWidth,
    button_orientation: buttonOrientation
  } = appearance ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || data == null) {
    return null
  }

  const topicContentClasses = clsx(
    'col',
    textColor !== 'body' && 'text-white',
    textWidth != null ? columnClasses[textWidth] : 'col-md-12'
  )

  const rowClasses = clsx(
    'row',
    'g-5',
    textPosition === 'center' && 'flex-column-reverse align-items-center',
    textPosition === 'left' && 'flex-column-reverse flex-md-row',
    textPosition === 'right' && 'flex-column-reverse flex-md-row-reverse'
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  return (
    <section className={background.className} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic'>
      <div className={containerClassNames}>
        <div className={rowClasses}>
          <div className={topicContentClasses}>
            <ContentstackTopicHeader
              data={header}
              textPosition={textPosition ?? ''}
              headingFontSize={appearance?.heading_font_size}
            />
            {body != null && (
              <ContentstackTopicBody
                buttonOrientation={buttonOrientation ?? 'horizontal'}
                textPosition={textPosition ?? ''}
                data={body}
                title={title}
              />
            )}
          </div>
          <ContentstackTopicFeaturedMedia data={featuredMedia} className='col my-auto d-flex justify-content-center' />
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackTopic
}
