import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useGetActiveCategoriesByIdsQuery } from '@stuller/stullercom/data-access/apollo-queries'

function useCategoryExcludeTargeting (targeting: TargetingMap['CATEGORY_EXCLUDE']): boolean {
  const { data } = useGetActiveCategoriesByIdsQuery({
    variables: {
      ids: targeting.targetValue
    },
    ssr: true
  })

  return data?.activeCategoriesByIds?.length != null ? data?.activeCategoriesByIds?.length > 0 : false
}

export { useCategoryExcludeTargeting }
