import { Carousel, CarouselControl, CarouselIndicators, CarouselInner, CarouselItem } from '@stuller/stullercom/ui'
import { clsx } from 'clsx'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { type ReactElement } from 'react'
import { type VideoCarouselInfoFragment, type YoutubeVideo } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import { useCounter } from '@stuller/shared/util/react-hooks'

interface ContentstackVideoCarouselProps {
  data?: VideoCarouselInfoFragment | null
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * The Video Carousel is a collection of videos that users can swipe through. Each video eases understanding of actions, ideas, or concepts. Videos are used to add meaning and create emotional connections within users.
 */
function ContentstackVideoCarousel ({ data }: ContentstackVideoCarouselProps): ReactElement | null {
  const {
    title,
    appearance,
    body,
    section_header: sectionHeader,
    targetingConnection
  } = data ?? {}

  // carousel variable definitions
  const carouselArray = body?.videos ?? [] as YoutubeVideo[]
  const [index, setIndex, handlePrev, handleNext] = useCounter(0, {
    max: carouselArray.length - 1
  })

  // background-specific classes for background images, colors, and padding
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')
  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8 d-print-none',
    spacing?.className
  )

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isCarouselEmpty = carouselArray.length === 0
  if (!showContent || isCarouselEmpty) {
    return null
  }

  // checker classes for conditional rendering
  const emptyBottomText = isRteEmpty(body?.rich_text_below_carousel?.json)
  const emptyBottomButton = isButtonEmpty(body?.button)

  // bottom rich text and button classes
  const bottomContentAlignmentClassName = (appearance?.button_rich_text_below_carousel_alignment != null && alignmentMap[appearance.button_rich_text_below_carousel_alignment]) ?? 'justify-content-start'
  const bottomTextClassNames = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName,
    sectionHeader?.appearance?.text_color === 'white' && 'text-white'
  )
  const buttonClassNames = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName
  )

  return (
    <section className={background.className} id={contentstackTitleToHtmlId(title)}>
      <div className={containerClassNames}>
        <div className='row flex-column flex-md-row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} title={title} />

          <div className='d-flex justify-content-center'>
            <Carousel
              className='w-100'
              index={index}
              length={carouselArray.length}
              onChange={setIndex}
              popout
              style={{ maxWidth: '900px' }}
            >
              <CarouselInner>
                {carouselArray?.map((item, i) => !isYoutubeVideoEmpty(item) && (
                  <CarouselItem key={i} itemIndex={i}>
                    <ContentstackYoutubeVideo data={item} key={index} maxWidth='100%' />
                  </CarouselItem>
                ))}
              </CarouselInner>
              <CarouselControl direction='prev' onClick={handlePrev} />
              <CarouselControl direction='next' onClick={handleNext} />
              <CarouselIndicators />
            </Carousel>
          </div>

          {!emptyBottomText && (
            <div className={bottomTextClassNames}>
              <p className='mb-0'>
                <ContentstackJsonRte json={body?.rich_text_below_carousel?.json} excludeOuterTags />
              </p>
            </div>)}

          {!emptyBottomButton && (
            <div className={buttonClassNames} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
              <ContentstackButton data={body?.button} data-gtm-click-subsection2='primary-cta' />
            </div>)}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackVideoCarousel
}
