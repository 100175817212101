import { type ReactNode } from 'react'
import { type SectionHeaderInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackSectionHeaderProps {
  data?: SectionHeaderInfoFragment | null
  className?: string
  title?: string | null
}

function ContentstackSectionHeader ({ data, className, title }: ContentstackSectionHeaderProps): ReactNode | null {
  const { appearance, body, header } = data ?? {}

  const isHeadingEmpty = isRteEmpty(header?.heading?.json)
  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)
  const isTitleEmpty = isStringEmpty(title)

  if (isHeadingEmpty && isBodyEmpty && isTitleEmpty) {
    return null
  }

  const textClasses = clsx(
    appearance?.text_position === 'left' && 'text-start',
    appearance?.text_position === 'center' && 'text-center',
    appearance?.text_position === 'right' && 'text-end',
    appearance?.text_color === 'white' && 'text-white',
    'px-4 px-md-0',
    className
  )

  const headingClasses = clsx(
    textClasses,
    appearance?.heading_font_size ?? ''
  )

  const containerClasses = clsx(
    'col-12 mb-n3 d-flex',
    appearance?.text_position === 'left' && 'justify-content-start',
    appearance?.text_position === 'center' && 'justify-content-center',
    appearance?.text_position === 'right' && 'justify-content-end'
  )

  return (
    <div className={containerClasses} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div style={{ maxWidth: '800px' }}>
        {!isHeadingEmpty && (
          <h2 className={headingClasses}>
            <ContentstackJsonRte excludeOuterTags json={header?.heading?.json} />
          </h2>
        )}
        {!isBodyEmpty && (
          <p className={textClasses}>
            <ContentstackJsonRte excludeOuterTags json={body?.rich_text?.json} />
          </p>
        )}
      </div>
    </div>
  )
}

export { ContentstackSectionHeader }
