import { type ReactElement } from 'react'
import { type CardWithBackground } from './types'
import { Card, CardBody } from '@stuller/stullercom/ui'
import { ContentstackCardWithBackgroundHeader } from './ContentstackCardWithBackgroundHeader'
import { ContentstackCardWithBackgroundBody } from './ContentstackCardWithBackgroundBody'
import { isStringEmpty } from '@stuller/shared/util/core'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'

interface ContentstackCardWithBackgroundProps {
  card?: CardWithBackground
  /**
   * determines column count of cards
   */
  columnClass: string
  /**
   * determines the glass effect overlay style of cards
   */
  overlayStyle: string | null
}

/**
 * Individual Card with Background rendered inside <ContentstackCardWithBackgroundGroup /> component instances
 * @memberof ContentstackCardWithBackgroundGroup
 */
function ContentstackCardWithBackground ({ card, columnClass, overlayStyle }: ContentstackCardWithBackgroundProps): ReactElement | null {
  const {
    title,
    header,
    body,
    appearance,
    targetingConnection
  } = card ?? {}

  const {
    image_xs: imageXs,
    image_sm: imageSm,
    image_md: imageMd,
    image_lg: imageLg,
    image_xl: imageXl,
    image_xxl: imageXxl,
    image_xxxl: imageXxxl
  } = appearance?.background_images?.image_sources ?? {}

  const hasBackgroundImage = (
    !isStringEmpty(imageXs) ||
    !isStringEmpty(imageSm) ||
    !isStringEmpty(imageMd) ||
    !isStringEmpty(imageLg) ||
    !isStringEmpty(imageXl) ||
    !isStringEmpty(imageXxl) ||
    !isStringEmpty(imageXxxl)
  )

  const background = useContentstackBackground(null, appearance?.background_images)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (header == null && body == null) {
    return null
  }

  const cardClassNames = clsx(
    'h-100 rounded-3',
    hasBackgroundImage && `text-${appearance?.text_color}`,
    background.className
  )

  const overlayClassNames = clsx(
    'overlay h-100 col-9 rounded-3 rounded-end-0',
    columnClass === 'col-md-6' ? 'col-lg-7 col-xl-6' : 'col-lg-8 col-xl-7',
    hasBackgroundImage && (appearance?.text_color === 'body' ? 'bg-glass-light' : 'bg-glass-dark'),
    overlayStyle === 'blur' && 'bg-glass-blur'
  )

  return (
    <div className={columnClass} data-gtm-click-section='card' data-gtm-click-subsection1={title}>
      <Card className='border-0'>
        <div className={cardClassNames}>
          <div className={overlayClassNames}>
            <CardBody className='p-4'>
              <div className='row gy-4'>
                <ContentstackCardWithBackgroundHeader
                  data={header}
                />
                <ContentstackCardWithBackgroundBody
                  data={body}
                />
              </div>
            </CardBody>
          </div>
          <style jsx>{`
            .overlay {
              min-height: 220px;

              @media (min-width: ${variables.breakpointLg}) {
                min-height: 280px;
              }
            }
          `}
          </style>
        </div>
      </Card>
      {background.styles}
    </div>
  )
}

export {
  ContentstackCardWithBackground
}
