import { type ReactElement } from 'react'
import { ContentstackMediaBoxHeader } from './ContentstackMediaBoxHeader'
import { ContentstackMediaBoxMedia } from './ContentstackMediaBoxMedia'
import { ContentstackMediaBoxBody } from './ContentstackMediaBoxBody'
import { type MediaBoxGroupMediaBoxes } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackMediaBoxGroupProps {
  data?: MediaBoxGroupMediaBoxes | null
  /**
   * determines column count of  Media Boxes
   */
  columnClass?: string
}

/**
 * Inidividual  Media Box inside of a ContentstackMediaGoxGroup
 * @memberof ContentstackMediaGoxGroup
 */
function ContentstackMediaBox ({
  data,
  columnClass
}: ContentstackMediaBoxGroupProps): ReactElement | null {
  const {
    title,
    header,
    body,
    media,
    appearance,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (header == null && body == null && media == null) {
    return null
  }

  const rowClassNames = clsx(
    'row gy-3 justify-content-center gx-3 gx-md-4',
    appearance?.text_position === 'left' && 'flex-row-reverse'
  )
  const mediaBoxContentClassNames = clsx(
    'col-12 col-md',
    appearance?.text_position === 'center' ? 'text-center' : 'text-center text-md-start'
  )

  return (
    <div className={columnClass} data-gtm-click-section='card' data-gtm-click-subsection1={title}>
      <div className={rowClassNames}>
        {media != null && (
          <ContentstackMediaBoxMedia data={media} />
        )}
        <div className={mediaBoxContentClassNames}>
          {header != null && (
            <ContentstackMediaBoxHeader
              data={header}
            />
          )}
          {body != null && (
            <ContentstackMediaBoxBody
              data={body}
              title={title}
              buttonOrientation={appearance?.button_orientation}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export {
  ContentstackMediaBox
}
