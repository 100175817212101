import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { ContentstackSmallShoppingBucket } from './ContentstackSmallShoppingBucket'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type ReactElement } from 'react'
import { type SmallShoppingBucketsInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackSmallShoppingBucketsProps {
  data?: SmallShoppingBucketsInfoFragment | null
}

/**
 * Component for small shopping buckets on category pages
 */
function ContentstackSmallShoppingBuckets ({ data }: ContentstackSmallShoppingBucketsProps): ReactElement | null {
  const {
    title,
    appearance,
    shopping_buckets: shoppingBuckets,
    section_header: sectionHeader,
    targetingConnection
  } = data ?? {}
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(shoppingBuckets)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const rowClassNames = clsx(
    'row g-3 g-md-5',
    appearance?.card_alignment === 'center' && 'text-center justify-content-center'
  )
  const containerClassNames = clsx(
    'container-xxxl py-6 py-md-8 px-4 mw-xxl',
    spacing?.className
  )

  return (
    <section className={background.className} id={contentstackTitleToHtmlId(title)}>
      <div className={containerClassNames}>
        <div className={rowClassNames}>
          <ContentstackSectionHeader data={sectionHeader} title={title} />
          {shoppingBuckets?.map((shoppingBucket, index) => shoppingBucket != null &&
            <ContentstackSmallShoppingBucket data={shoppingBucket} title={title} key={index} />
          )}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackSmallShoppingBuckets
}
