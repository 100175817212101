import { type ReactElement, useMemo } from 'react'
import { type EscapeHatchInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import * as ContentstackEscapeHatchComponents from '@stuller/stullercom/feat/contentstack-escape-hatch'
import { ContentstackEscapeHatchErrorBoundary } from './ContentstackEscapeHatchErrorBoundary'

interface ContentstackEsacpeHatchProps {
  data?: EscapeHatchInfoFragment | null
}

/**
 * A CMS user should be able to add a YouTube video to a page.
 *
 * The video should match designs and have all configurations matching the build kit.
 */
function ContentstackEscapeHatch ({ data }: ContentstackEsacpeHatchProps): ReactElement | null {
  const {
    component_name: componentName,
    data: componentData
  } = data ?? {}
  const Component = useMemo(() => {
    if (componentName != null) {
      return ContentstackEscapeHatchComponents[`Contentstack${componentName}` as keyof typeof ContentstackEscapeHatchComponents]
    }

    return null
  }, [componentName])

  if (Component == null || componentData == null) {
    return null
  }

  return (
    <ContentstackEscapeHatchErrorBoundary>
      <Component data={componentData[0]} />
    </ContentstackEscapeHatchErrorBoundary>
  )
}

export {
  ContentstackEscapeHatch
}
