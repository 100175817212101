import { type ReactElement } from 'react'
import { type BulletListItem, type DefaultIcon } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackBulletListItemIcon } from './ContentstackBulletListItemIcon'
import { isStringEmpty } from '@stuller/shared/util/core'
import { isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'

interface ContentstackBulletListItemProps {
  defaultIcon?: DefaultIcon
  item: BulletListItem
}

/**
 * Individual item rendered inside <ContentstackIconList /> component instances
 * @memberof ContentstackBulletList
 */
function ContentstackBulletListItem ({ defaultIcon, item }: ContentstackBulletListItemProps): ReactElement {
  const isItemIconEmpty = isStringEmpty(item?.icon?.name) && isSimpleImageEmpty(item?.icon?.icon_image)
  const icon = isItemIconEmpty ? defaultIcon : item?.icon
  const richText = item?.rich_text?.json

  return (
    <li className='mb-5 p-0'>
      <div className='row gx-3'>
        <div className='col-auto'>
          <ContentstackBulletListItemIcon icon={icon} />
        </div>
        {!isRteEmpty(richText) && (
          <div className='col mb-n3'>
            <ContentstackJsonRte json={richText} />
          </div>
        )}
      </div>
    </li>
  )
}

export {
  ContentstackBulletListItem
}
