import { checkTargeting } from './useContentstackTargeting'
import type { Targeting } from '@stuller/contentstack/util/types'

interface ItemAndTargeting {
  item: any
  targeting?: Targeting | any
}

/**
 * hook to check targeting of multiple items and return only the items that pass targeting
 */
function useFilterContentstackTargeting<T extends ItemAndTargeting> (itemsWithTargeting: T[] | null | undefined): Array<T['item']> {
  if (itemsWithTargeting == null) {
    return []
  }

  const filtered = itemsWithTargeting.filter(({ targeting }) => checkTargeting(targeting))

  return filtered.map(({ item }) => item)
}

export {
  useFilterContentstackTargeting
}
