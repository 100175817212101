import { AccordionBody, AccordionHeader, AccordionItem } from '@stuller/stullercom/ui'
import { ContentstackButton } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackYoutubeVideo } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type AccordionAccordionItem } from './types'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackAccordionItemProps {
  data: AccordionAccordionItem
  /**
   * Index of accordion item in the list
   */
  index: number
  /**
   * True if the accordion item is the last item in the list
   */
  lastItem: boolean
  /**
   * Background color of accordion item headers
   */
  headerColor: string
}

/**
 * Inidividual Accordion Item inside of a ContentstackAccordion
 * @memberof ContentstackAccordion
 */
function ContentstackAccordionItem ({ data, index, lastItem, headerColor }: ContentstackAccordionItemProps): ReactElement | null {
  const {
    body,
    heading,
    targetingConnection,
    title
  } = data ?? {}

  const {
    appearance,
    button,
    media,
    rich_text: richText
  } = body ?? {}

  const hasHeading = !isRteEmpty(heading?.json)
  const hasImage = !isStringEmpty(media?.image?.image_source)
  const hasVideo = !isStringEmpty(media?.video?.video?.id as string)
  const hasRichText = !isRteEmpty(richText?.json)
  const hasButton = !isRteEmpty(button?.text?.json)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (!hasHeading && !hasRichText && !hasImage && !hasVideo && !hasButton) {
    return null
  }

  const accordionItemClassNames = clsx(
    lastItem && 'mb-0'
  )

  const bodyClassNames = clsx(
    headerColor === 'bg-white' ? 'border-top border-gray-400' : ''
  )

  const rowClassNames = clsx(
    'row gy-4',
    appearance?.text_position === 'left' && 'flex-md-row-reverse flex-column-reverse',
    appearance?.text_position === 'right' && 'justify-content-end flex-column-reverse flex-md-row'
  )

  const textContainerClassNames = clsx(
    (hasImage || hasVideo) && 'col-md-6'
  )

  return (
    <AccordionItem className={accordionItemClassNames} itemId={`${index}`} data-gtm-click-subsection1={title}>
      <AccordionHeader buttonClassName={headerColor}>
        {hasHeading ? <ContentstackJsonRte excludeOuterTags json={heading?.json} /> : ''}
      </AccordionHeader>
      <AccordionBody className={bodyClassNames}>
        <div className='col-12'>
          <div className={rowClassNames}>

            {hasImage && (
              <div className='col-12 col-md-6'>
                <ContentstackSimpleImage
                  className='img-fluid rounded object-fit-cover h-100 w-100'
                  data={media.image}
                />
              </div>
            )}

            {hasVideo && (
              <div className='col-12 col-md-6'>
                <ContentstackYoutubeVideo data={media?.video} />
              </div>
            )}

            {(hasRichText || hasButton) && (
              <div className={textContainerClassNames}>
                {hasRichText && (
                  <div className='col-12'>
                    <ContentstackJsonRte excludeOuterTags json={richText?.json} />
                  </div>)}
                {hasButton && (
                  <div className='col-12 mt-3'>
                    <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
                  </div>)}
              </div>)}

          </div>
        </div>
      </AccordionBody>
    </AccordionItem>
  )
}

export {
  ContentstackAccordionItem
}
