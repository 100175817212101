import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackCardWithBackground } from './ContentstackCardWithBackground'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type CardWithBackgroundGroup } from './types'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackCardWithBackgroundGroupProps {
  data?: CardWithBackgroundGroup
}

const cardGroupColumns: Record<string, string> = {
  half: 'col-md-6',
  third: 'col-md-6 col-lg-4'
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * The Card with Background Group is a collection of cards.
 *
 * Each Card with Background groups content relating to a single topic together in a way that makes it distinct from the surrounding content.
 */
function ContentstackCardWithBackgroundGroup ({ data }: ContentstackCardWithBackgroundGroupProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    cards_with_background: cards,
    button,
    appearance,
    targetingConnection
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(cards)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const cardWidth = appearance?.card_width != null ? appearance.card_width : 'half'
  const columns = cardGroupColumns[cardWidth]
  const emptyButton = isButtonEmpty(button)

  const buttonClassNames = clsx(
    'col-12 mt-6 d-flex',
    (appearance?.button_alignment != null && alignmentMap[appearance.button_alignment]) ?? 'justify-content-start'
  )
  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  return (
    <section className={background.className} id={contentstackTitleToHtmlId(title)}>
      <div className={containerClassNames}>
        <div className='row flex-column flex-md-row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} title={title} />
          {cards?.map((card, index) => (
            <ContentstackCardWithBackground
              card={card}
              key={index}
              columnClass={columns}
              overlayStyle={appearance?.overlay_style ?? ''}
            />))}
          {!emptyButton && (
            <div
              className={buttonClassNames}
              data-gtm-click-section='topic'
              data-gtm-click-subsection1={title}
            >
              <ContentstackButton
                data={button}
                data-gtm-click-subsection2='primary-cta'
              />
            </div>)}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackCardWithBackgroundGroup
}
