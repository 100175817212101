import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackMediaBox } from './ContentstackMediaBox'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type MediaBoxGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackMediaBoxGroupProps {
  data?: MediaBoxGroupInfoFragment | null
}

const mediaBoxColumns: Record<string, string> = {
  full: 'col-md-12',
  half: 'col-md-6'
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * A CMS user should be able to add a group of  Media Boxes to a content page
 * The  Media Box Group is a collection of containers that optionally include featured media, text, and buttons.
 */
function ContentstackMediaBoxGroup ({ data }: ContentstackMediaBoxGroupProps): ReactElement | null {
  const {
    title,
    appearance,
    button,
    section_header: sectionHeader,
    media_boxes: mediaBoxes,
    targetingConnection
  } = data ?? {}

  const mediaBoxWidth = appearance?.media_box_width != null ? appearance.media_box_width : 'full'
  const columnClass = mediaBoxColumns[mediaBoxWidth]

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const emptyButton = isButtonEmpty(button)
  const containerClassNames = clsx(
    'container-xxxl mw-xxl py-6 py-md-8 px-4',
    spacing?.className
  )

  const buttonClassNames = clsx(
    'col-12 mt-6 d-flex',
    (appearance?.button_alignment != null && alignmentMap[appearance.button_alignment]) ?? 'justify-content-start'
  )

  return (
    <div className={background.className} id={contentstackTitleToHtmlId(title)}>
      <div className={containerClassNames}>
        <div className='row flex-column justify-content-center flex-md-row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} title={title} />
          {mediaBoxes?.map((data, i) => data != null && (
            <ContentstackMediaBox
              key={i}
              data={data}
              columnClass={columnClass}
            />)
          )}
          {!emptyButton && (
            <div className={buttonClassNames} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
              <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
            </div>)}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </div>
  )
}

export {
  ContentstackMediaBoxGroup
}
