import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

function useShipToAccountTargeting (targeting: TargetingMap['SHIP_TO_ACCOUNT']): boolean {
  const { authUser } = useAuth()
  const shipToId = authUser?.user?.defaultActiveShipToAccount?.id

  return shipToId == null ? false : targeting.targetValue.includes(shipToId)
}

export { useShipToAccountTargeting }
