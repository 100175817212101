import css from 'styled-jsx/css'
import {
  type Background,
  type BackgroundCustomBackgroundGradient,
  type BackgroundCustomBackgroundImageImageSources,
  type Maybe,
  type ResponsiveImage,
  type ResponsiveImageImageSources
} from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { isStringEmpty } from '@stuller/shared/util/core'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'

function getBackgroundColor (color: Maybe<string> | undefined, customColor: Maybe<string> | undefined): string | null {
  if (!isStringEmpty(customColor)) {
    return `${customColor}`
  } else if (color != null && color !== '') {
    return `var(--bs-${color})`
  }

  return null
}

function getBackgroundColorStyles (backgroundColor: string | null): string {
  if (backgroundColor == null) {
    return ''
  }

  return `
    background-color: ${backgroundColor};
  `
}

function getBackgroundGradientStyles (backgroundColor: string | null, gradient: Maybe<BackgroundCustomBackgroundGradient> | undefined): string {
  const gradientEndColor = getBackgroundColor(gradient?.end_color, gradient?.custom_end_color)

  if (gradientEndColor == null) {
    return ''
  }

  return `
    background-image: linear-gradient(
      ${gradient?.gradient_direction !== null ? `${gradient?.gradient_direction},` : ''}
      ${backgroundColor},
      ${gradientEndColor}
    );
  `
}

function getImageStyles (imageSource: Maybe<string> | undefined, breakpoint?: string): string {
  if (isStringEmpty(imageSource)) {
    return ''
  }

  if (breakpoint != null) {
    return `
      @media (min-width: ${breakpoint}) {
        background-image: url(${imageSource});
      }
    `
  } else {
    return `background-image: url(${imageSource});`
  }
}

function getBackgroundImageStyles (imageSources: Maybe<BackgroundCustomBackgroundImageImageSources> | Maybe<ResponsiveImageImageSources> | undefined): string {
  const {
    image_xs: imageXs,
    image_sm: imageSm,
    image_md: imageMd,
    image_lg: imageLg,
    image_xl: imageXl,
    image_xxl: imageXxl,
    image_xxxl: imageXxxl
  } = imageSources ?? {}

  const isImageEmpty: boolean = (
    isStringEmpty(imageXs) &&
    isStringEmpty(imageSm) &&
    isStringEmpty(imageMd) &&
    isStringEmpty(imageLg) &&
    isStringEmpty(imageXl) &&
    isStringEmpty(imageXxl) &&
    isStringEmpty(imageXxxl)
  )

  if (isImageEmpty) {
    return ''
  }

  return `
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    ${getImageStyles(imageXs)}
    ${getImageStyles(imageSm, variables.breakpointSm)}
    ${getImageStyles(imageMd, variables.breakpointMd)}
    ${getImageStyles(imageLg, variables.breakpointLg)}
    ${getImageStyles(imageXl, variables.breakpointXl)}
    ${getImageStyles(imageXxl, variables.breakpointXxl)}
    ${getImageStyles(imageXxxl, variables.breakpointXxxl)}
  `
}

/**
 * The Background is a solid color, gradient, image, or video that can be reused by many components.
 * It can draw attention to a point on the page, call out important information, group related content, and connect information.
 */
function useContentstackBackground (background?: Maybe<Background> | undefined, reponsiveImage?: Maybe<ResponsiveImage> | undefined): ReturnType<typeof css.resolve> {
  const {
    default_color: defaultColor,
    custom_background: customBackground
  } = background ?? {}

  const backgroundColor = getBackgroundColor(defaultColor?.color, defaultColor?.custom_color)
  const backgroundColorStyles = getBackgroundColorStyles(backgroundColor)
  const backgroundGradientStyles = getBackgroundGradientStyles(backgroundColor, customBackground?.gradient)
  const backgroundImageStyles = getBackgroundImageStyles(background != null ? customBackground?.image?.image_sources : reponsiveImage?.image_sources)

  if (backgroundColorStyles == null && backgroundGradientStyles == null && backgroundImageStyles == null) {
    return css.resolve``
  } else if (customBackground?.video != null && customBackground?.video !== '') {
    const result = css.resolve`
    * {
      position: relative;
      ${backgroundColorStyles}
      ${backgroundGradientStyles}
      ${backgroundImageStyles}

      & > * {
        position: relative;
        z-index: 1;
      }
    }
  `

    return {
      ...result,
      styles: (
        <>
          {result.styles}
          <video autoPlay className='bg-video d-none d-sm-block' muted loop>
            <source src={customBackground.video} type='video/mp4' />
          </video>
        </>
      )
    }
  }

  return css.resolve`
    * {
      ${backgroundColorStyles}
      ${backgroundGradientStyles}
      ${backgroundImageStyles}
    }
  `
}

export {
  useContentstackBackground
}
