import { type ReactElement } from 'react'
import { type MediaBoxGroupMediaBoxesHeader } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import clsx from 'clsx'
import { ContentstackSimpleImage } from '../contentstack-simple-image/ContentstackSimpleImage'

interface ContentstackMediaBoxHeaderProps {
  data: MediaBoxGroupMediaBoxesHeader
  /**
   * Determines left or center aligned text
   */
  alignmentClass?: string
}

/**
 * Renders the heading, subheading, and badge of a ContentstackMediaBox
 * @memberof ContentstackMediaGoxGroup
 */
function ContentstackMediaBoxHeader ({ data, alignmentClass }: ContentstackMediaBoxHeaderProps): ReactElement | null {
  const {
    heading,
    subheading,
    heading_image: headingImage
  } = data ?? {}
  const emptyHeadingJson = isRteEmpty(heading?.json)
  const emptySubheadingJson = isRteEmpty(subheading?.json)

  if (emptyHeadingJson && emptySubheadingJson && headingImage == null) {
    return null
  }

  const headerClasses = clsx(
    alignmentClass,
    'col-12 mb-n3'
  )

  return (
    <div className={headerClasses}>
      {headingImage != null && headingImage.image_source?.trim() !== '' && (
        <div className='mb-3'>
          <ContentstackSimpleImage data={headingImage} />
        </div>)}
      {heading?.json != null && !emptyHeadingJson && (
        <h2>
          <ContentstackJsonRte excludeOuterTags json={heading.json} />
        </h2>)}
      {subheading?.json != null && !emptySubheadingJson && (
        <h3 className='fw-normal mt-n3'>
          <ContentstackJsonRte excludeOuterTags json={subheading.json} />
        </h3>)}
    </div>
  )
}

export {
  ContentstackMediaBoxHeader
}
