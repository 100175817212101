import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if website is in Showcase Mode or Jeweler Showcase mode, or not in either
 */
function useShowcaseTargeting (targeting: TargetingMap['SHOWCASE']): boolean {
  const auth = useAuth()
  const { isShowcase } = auth

  return targeting.targetValue ? isShowcase : !isShowcase
}

export { useShowcaseTargeting }
