import { type PageHeader } from './types'
import { ContentstackPageHeaderBody } from './ContentstackPageHeaderBody'
import { ContentstackPageHeaderFeaturedMedia } from './ContentstackPageHeaderFeaturedMedia'
import { ContentstackPageHeaderHeader } from './ContentstackPageHeaderHeader'
import { type ReactElement } from 'react'
import { stringToHtmlId } from '@stuller/shared/util/core'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackPageHeaderProps {
  data?: PageHeader
}

/**
 * The Header appears at the top of each page to textually and visually communicate what users can expect to encounter beneath it. It can be used to orient users as to where they are and provide introductory content about the page.
 */
function ContentstackPageHeader ({ data }: ContentstackPageHeaderProps): ReactElement | null {
  const {
    title,
    appearance,
    body,
    featured_media: featuredMedia,
    header,
    targetingConnection
  } = data ?? {}

  // background-specific classes for background images, colors, and padding
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || data == null) {
    return null
  }

  // background classes
  const appearanceClassNames = clsx(
    'flex position-relative overflow-hidden',
    background.className,
    'text-' + appearance?.text_color
  ) ?? ''

  // inner container classes
  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  // text position of left or right column
  const rowPositionClassNames = clsx(
    'row hx-0 gx-md-5 gy-5 gy-md-0',
    appearance?.text_position === 'right' && 'flex-row-reverse'
  )

  // text alignment checks
  const textColumnAlignmentClassNames = clsx(
    'col my-auto',
    'text-md-' + appearance?.text_position
  )

  return (
    <header className={appearanceClassNames} id={stringToHtmlId(title, 'page-')}>
      <div className={containerClassNames}>
        <div className={rowPositionClassNames}>
          <div className='col-md-6'>
            <div className='row h-100'>
              <div className={textColumnAlignmentClassNames}>
                <ContentstackPageHeaderHeader
                  data={header}
                  headingFontSize={appearance?.heading_font_size}
                />
                <ContentstackPageHeaderBody
                  data={body}
                  buttonOrientation={appearance?.button_orientation}
                  title={title}
                />
              </div>
            </div>
          </div>
          <ContentstackPageHeaderFeaturedMedia
            data={featuredMedia}
          />
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </header>
  )
}

export {
  ContentstackPageHeader
}
