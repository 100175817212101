import { type ReactElement } from 'react'
import clsx from 'clsx'
import { type Maybe, type SocialIconsFeatureSocialMediaIcons } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import Link from 'next/link'

interface ContentstackSocialIconProps {
  socialMediaIcon?: Maybe<SocialIconsFeatureSocialMediaIcons>
  /**
   * Color of icon that is also used to determine the color of the circle around the icon
   */
  iconColor?: string
  /**
   * Index of icon in the list
   */
  index: number
}

/**
 * Individual Social Icon rendered inside <ContentstackSocialIconsFeature />
 * @memberof ContentstackSocialIconsFeature
 */
function ContentstackSocialIcon ({ socialMediaIcon, iconColor, index }: ContentstackSocialIconProps): ReactElement | null {
  const {
    icon,
    title,
    link
  } = socialMediaIcon ?? {}

  if (socialMediaIcon == null) {
    return null
  }

  const cmsIconCircleSize: number = 50
  const wrapperClasses = clsx(
    'd-flex flex-column align-items-center justify-content-center rounded-circle',
    iconColor === 'blue-500' ? 'bg-white' : 'bg-blue-500',
    `text-${iconColor}`
  )

  return (
    <>
      {(index % 3 === 0 && index !== 0) && <div className='w-100 d-md-none' />}
      <div className='col-auto'>
        <Link
          href={link?.href ?? ''}
          title={link?.title ?? ''}
          className={wrapperClasses}
          style={{
            maxHeight: cmsIconCircleSize,
            maxWidth: cmsIconCircleSize,
            minHeight: cmsIconCircleSize,
            minWidth: cmsIconCircleSize
          }}
          data-gtm-click-text={title}
        >
          <ContentstackIcon
            icon={icon}
            size='2x'
            defaultIcon='fas-link'
          />
        </Link>
      </div>
    </>
  )
}

export {
  ContentstackSocialIcon
}
