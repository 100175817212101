import { Card, CardBody } from '@stuller/stullercom/ui'
import { clsx } from 'clsx'
import { ContentstackButton } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import { type VerticalBackgroundCardGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'

interface ContentstackVerticalBackgroundCardGroupProps {
  data?: NonNullable<VerticalBackgroundCardGroupInfoFragment['vertical_background_cards']>[0]
  index?: number
}

function ContentstackVerticalBackgroundCard ({ data, index }: ContentstackVerticalBackgroundCardGroupProps): ReactElement | null {
  const {
    title,
    appearance,
    background_image: backgroundImage,
    body,
    header,
    targetingConnection
  } = data ?? {}

  const cardHeight: number = 400
  const cardWidth: number = 300

  const {
    image_xs: imageXs,
    image_sm: imageSm,
    image_md: imageMd,
    image_lg: imageLg,
    image_xl: imageXl,
    image_xxl: imageXxl,
    image_xxxl: imageXxxl
  } = backgroundImage?.image_sources ?? {}

  const hasBackgroundImage = (
    (imageXs != null && imageXs !== '') ||
    (imageSm != null && imageSm !== '') ||
    (imageMd != null && imageMd !== '') ||
    (imageLg != null && imageLg !== '') ||
    (imageXl != null && imageXl !== '') ||
    (imageXxl != null && imageXxl !== '') ||
    (imageXxxl != null && imageXxxl !== '')
  )

  const background = useContentstackBackground(null, backgroundImage)

  const cardClassNames = clsx(
    'h-100 rounded-3 border-0',
    hasBackgroundImage && `text-${appearance?.text_color}`,
    background.className
  )

  const cardBodyClassNames = clsx(
    'h-100 p-5 d-flex',
    'flex-column flex-nowrap align-items-start justify-content-between'
  )

  const isHeadingEmpty = isRteEmpty(header?.heading?.json)
  const isBodyEmpty = isRteEmpty(body?.button?.text?.json)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  return (
    <Card
      className={cardClassNames}
      style={{
        maxHeight: cardHeight,
        maxWidth: cardWidth,
        minHeight: cardHeight,
        minWidth: cardWidth
      }}
      data-gtm-click-section='card'
      data-gtm-click-subsection1-index={index}
      data-gtm-click-subsection1={title}
    >
      <CardBody
        className={cardBodyClassNames}
      >
        {!isHeadingEmpty && (
          <h2
            className='mb-auto'
          >
            <ContentstackJsonRte excludeOuterTags json={header?.heading?.json} />
          </h2>
        )}
        {!isBodyEmpty && (
          <ContentstackButton
            data={body?.button}
            data-gtm-click-subsection2='primary-cta'
          />
        )}
      </CardBody>
      {background.styles}
    </Card>
  )
}

export {
  ContentstackVerticalBackgroundCard
}
