import { MultiItemCarousel, MultiItemCarouselControl, MultiItemCarouselIndicators, MultiItemCarouselInner, MultiItemCarouselItem, useMultiItemCarousel } from '@stuller/stullercom/ui'
import { stringToHtmlId } from '@stuller/shared/util/core'
import { type BlogCardGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { ContentstackBlogCard } from './ContentstackBlogCard'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'

interface ContentstackBlogCardGroupDisplayProps {
  appearance?: BlogCardGroupInfoFragment['appearance']
  cards?: BlogCardGroupInfoFragment['cards'] | null
}

function ContentstackBlogCardGroupDisplay ({
  appearance,
  cards
}: ContentstackBlogCardGroupDisplayProps): ReactElement | null {
  const multiItemCarouselProps = useMultiItemCarousel()

  if (appearance?.display_type === 'carousel') {
    return (
      <MultiItemCarousel {...multiItemCarouselProps}>
        <MultiItemCarouselInner gap='2rem' className='pb-4 pb-md-0'>
          {cards?.map((card, index) => (
            <MultiItemCarouselItem id={stringToHtmlId(card?.title, 'card-')} key={index}>
              <ContentstackBlogCard
                card={card}
                index={index}
                className='contentstack-carousel-blog-card'
              />
            </MultiItemCarouselItem>
          ))}
        </MultiItemCarouselInner>
        <MultiItemCarouselControl direction='prev' />
        <MultiItemCarouselControl direction='next' />
        <MultiItemCarouselIndicators />
        <style jsx global>{`
            .contentstack-carousel-blog-card {
              width: 330px;
            }

            @media (min-width: ${variables.breakpointMd}) {
              .contentstack-carousel-blog-card {
                width: 350px;
              }
            }

            @media (min-width: ${variables.breakpointXl}) {
              .contentstack-carousel-blog-card {
                width: 450px;
              }
            }
        `}
        </style>
      </MultiItemCarousel>
    )
  }

  return (
    <>
      {cards?.map((card, index) => (
        <div className='col-12 col-md-4' key={index}>
          <ContentstackBlogCard card={card} />
        </div>
      ))}
    </>
  )
}

export {
  ContentstackBlogCardGroupDisplay
}
