import { type ReactElement } from 'react'
import { type HighlightFeatureMediaDetails } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'

interface ContentstackHighlightFeatureMediaProps {
  data?: HighlightFeatureMediaDetails | null
}

/**
 * Component for individual featured media and its header text
 */
function ContentstackHighlightFeatureMedia ({ data }: ContentstackHighlightFeatureMediaProps): ReactElement | null {
  const {
    heading,
    image
  } = data ?? {}

  if (isSimpleImageEmpty(image)) {
    return null
  }
  const headingIsEmpty = isRteEmpty(heading?.json)

  return (
    <div className='text-center col-12 p-0'>
      <div className='row gy-4 justify-content-center'>
        <div className='col-12 p-0 rounded-circle overflow-hidden border border-3' style={{ aspectRatio: '1', height: 150, width: 150 }}>
          <ContentstackSimpleImage data={image} className='object-fit-cover w-100 h-100' />
        </div>
        {!headingIsEmpty && (
          <div className='col-12'>
            <h3 className='fw-normal'>
              <ContentstackJsonRte json={heading?.json} excludeOuterTags />
            </h3>
          </div>
        )}
      </div>
    </div>
  )
}

export {
  ContentstackHighlightFeatureMedia
}
