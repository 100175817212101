import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'
import { type PopOutFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'

interface ContentstackPopOutFeatureProps {
  data: PopOutFeatureInfoFragment | null
}

/**
 * The Pop Out Feature is used to highlight an image in a way that stands out from surrounding content.
 */
function ContentstackPopOutFeature ({ data }: ContentstackPopOutFeatureProps): ReactElement | null {
  const {
    title,
    appearance,
    header,
    body,
    targetingConnection,
    featured_image: featuredImage
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  if (!showContent || data == null) {
    return null
  }

  const rowClassNames = clsx(
    'row align-items-center flex-row align-items-stretch justify-content-between position-relative px-4 gy-6 gy-md-0 rounded-3 py-6 py-md-8',
      `bg-${appearance?.card_color ?? 'gray-100'}`
  )

  return (
    <section className={background.className} id={contentstackTitleToHtmlId(title)}>
      <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
        <div className={rowClassNames}>
          <div className='col-md-8 text-center text-md-start'>
            <div className='row align-items-center flex-row'>
              {!isSimpleImageEmpty(header?.heading_image) && (
                <div className='col'>
                  <ContentstackSimpleImage data={header?.heading_image} />
                </div>
              )}
              <div className='col-12 col-md-10'>
                {!isRteEmpty(header?.heading?.json) && (
                  <h3><ContentstackJsonRte excludeOuterTags json={header?.heading?.json} /></h3>
                )}
                {!isRteEmpty(body?.rich_text?.json) && (
                  <ContentstackJsonRte excludeOuterTags json={body?.rich_text?.json} />
                )}
              </div>
            </div>
          </div>
          <div className='col-md-4 d-flex align-items-center justify-content-center'>
            <ContentstackSimpleImage data={featuredImage} className='position-relative position-md-absolute h-100' />
            <style jsx>{`
              * {
                  @media (min-width: ${variables.breakpointMd}) {
                    img {
                      transform: scale(1.25);
                    }
                  }
                  @media (min-width: ${variables.breakpointLg}) {
                    img {
                    transform: scale(1.5);
                    }
                  }
                }
            `}
            </style>
          </div>
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackPopOutFeature
}
