import { type ReactElement } from 'react'
import { ModalHeader } from '@stuller/stullercom/ui'
import { type ContentstackModalProps } from './ContentstackModal'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'

/**
 * Contains the important information that helps users understand the structure and find what they are looking for
 */
function ContentstackModalHeader ({ data, toggleModalOpen }: ContentstackModalProps): ReactElement {
  const { header } = data ?? {}
  const hasEmptyImage = isSimpleImageEmpty(header?.image)
  const hasEmptySubheading = isRteEmpty(header?.subheading?.json)
  const hasEmptyHeading = isRteEmpty(header?.heading?.json)

  return (
    <ModalHeader onToggle={toggleModalOpen} tag='div'>
      {!hasEmptyImage && (
        <ContentstackSimpleImage data={header?.image ?? {}} />
      )}

      {!hasEmptySubheading && (
        <h1 className='modal-title'>
          <ContentstackJsonRte json={header?.heading?.json} excludeOuterTags />
        </h1>
      )}

      {!hasEmptyHeading && (
        <h2 className='modal-subtitle'>
          <ContentstackJsonRte json={header?.subheading?.json} excludeOuterTags />
        </h2>
      )}
    </ModalHeader>
  )
}

export {
  ContentstackModalHeader
}
