import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if user is an ever&ever member or not
 */
function useEverAndEverMemberTargeting (targeting: TargetingMap['EVER_AND_EVER_MEMBER']): boolean {
  const auth = useAuth()
  const { authUser } = auth
  const isEverAndEverMember = authUser?.user?.isEverAndEverMember ?? false

  return targeting.targetValue ? isEverAndEverMember : !isEverAndEverMember
}

export { useEverAndEverMemberTargeting }
