import { COMPANY_PHONE_NUMBER } from '@stuller/shared/util/constants'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type CallUsFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

export interface ContentstackCallUsFeatureProps {
  data?: CallUsFeatureInfoFragment | null
}

function ContentstackCallUsFeature ({ data }: ContentstackCallUsFeatureProps): ReactElement | null {
  const {
    title,
    appearance,
    body,
    targetingConnection
  } = data ?? {}

  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const sectionClassNames = clsx(
    appearance?.text_alignment != null && `text-${appearance?.text_alignment}`,
    appearance?.background_color != null && `bg-${appearance?.background_color}`
  )

  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)
  const phoneNumber = body?.phone_number ?? COMPANY_PHONE_NUMBER

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)}>
      <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
        <div className='row flex-column flex-md-row'>
          <h2 className='mb-2'>Contact Us</h2>

          <h5>We're here to help</h5>

          {!isBodyEmpty && (
            <ContentstackJsonRte json={body?.rich_text?.json} />)}

          <div className='mb-3'>
            <ContentstackIcon
              icon='fas-phone'
              size='2x'
              className='text-blue-300 me-3'
              defaultIcon='fas-phone'
            />
            <a className='h2 link-dark text-decoration-none' href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </a>
            {body?.option != null && (
              <span className='fw-bold ms-3'>option {body?.option}</span>)}
          </div>

          {body?.extension != null && (
            <div>Enter <span className='fw-bold'>ext. {body?.extension}</span> to connect with our expert team</div>)}

        </div>
      </div>
    </section>
  )
}

export { ContentstackCallUsFeature }
