import { type ReactElement } from 'react'
import { type YoutubeVideoInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackYoutubeVideoProps {
  data?: YoutubeVideoInfoFragment | null
  maxWidth?: string
}

/**
 * Helper function to not render any parent elements when the youtube video is empty
 */
function isYoutubeVideoEmpty (data?: YoutubeVideoInfoFragment | null): boolean {
  return isStringEmpty(data?.video?.id as string)
}

/**
 * A CMS user should be able to add a YouTube video to a page.
 *
 * The video should match designs and have all configurations matching the build kit.
 */
function ContentstackYoutubeVideo ({ data, maxWidth }: ContentstackYoutubeVideoProps): ReactElement | null {
  const {
    accessibility_title: title,
    video
  } = data ?? {}

  if (isYoutubeVideoEmpty(data)) {
    return null
  }

  return (
    <iframe
      allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      className='d-block w-100 rounded-3'
      loading='lazy'
      referrerPolicy='strict-origin-when-cross-origin'
      src={`https://www.youtube.com/embed/${video?.id}?mute=1`}
      style={{ aspectRatio: '16 / 9', maxWidth: maxWidth ?? '700px' }}
      title={title ?? ''}
    />
  )
}

export {
  ContentstackYoutubeVideo,
  isYoutubeVideoEmpty
}
