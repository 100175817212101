import { type ReactElement } from 'react'
import { type ButtonInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackButtonLink } from './ContentstackButtonLink'
import { ContentstackButtonModal } from './ContentstackButtonModal'
import { isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { isStringEmpty } from '@stuller/shared/util/core'

export interface ContentstackButtonProps {
  data?: ButtonInfoFragment | null
}

/**
 * Helper function to not render any parent elements when the button is empty
 */
function isButtonEmpty (data?: ButtonInfoFragment | null): boolean {
  const isButtonTextEmpty = isRteEmpty(data?.text?.json) && isStringEmpty(data?.icon_left) && isStringEmpty(data?.icon_right)
  const isButtonHrefEmpty = isStringEmpty(data?.link?.url?.href) && (data?.modalConnection?.edges == null || data?.modalConnection?.edges?.length < 1)

  return isButtonTextEmpty || isButtonHrefEmpty
}

/**
 * The Button is a visual prompt that includes text meant to encourage users to click to perform an action.
 *
 * Use buttons as a standalone element or in addition to other components to boost users’ interest in taking the next step.
 */
function ContentstackButton ({ data, ...otherAttributes }: ContentstackButtonProps): ReactElement | null {
  if (isButtonEmpty(data)) {
    return null
  }

  const isModalToggle = data?.modalConnection?.edges?.length !== 0

  if (isModalToggle) {
    return <ContentstackButtonModal data={data} {...otherAttributes} />
  }

  return (
    <ContentstackButtonLink data={data} {...otherAttributes} />
  )
}

export {
  ContentstackButton,
  isButtonEmpty
}
