import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if web user is authenticated or not
 */
function useAuthenticatedTargeting (targeting: TargetingMap['AUTHENTICATED']): boolean {
  const auth = useAuth()
  const { authUser } = auth

  return targeting.targetValue ? authUser != null : authUser == null
}

export { useAuthenticatedTargeting }
