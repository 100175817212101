import { type ReactElement } from 'react'
import { type LargeShoppingBucket } from './types'
import { Card } from '@stuller/stullercom/ui'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackLargeShoppingBucketHeader } from './ContentstackLargeShoppingBucketHeader'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackLargeShoppingBucketProps {
  data: LargeShoppingBucket
  title?: string | null
}

/**
 * Component for individual large shopping buckets that are displayed as a group on category pages
 */
function ContentstackLargeShoppingBucket ({ data, title }: ContentstackLargeShoppingBucketProps): ReactElement | null {
  const {
    title: bucketTitle,
    header,
    image,
    appearance,
    body,
    link,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)
  const isImageEmpty = isSimpleImageEmpty(image)

  const cardImageClassNames = clsx(
    'overflow-hidden d-flex',
    appearance?.image_alignment === 'center' ? 'justify-content-center' : appearance?.image_alignment === 'right' ? 'justify-content-end' : 'justify-content-start',
    appearance?.image_padding === true && 'p-4 pb-0'
  )
  const cardBodyClasses = clsx(
    'flex-fill d-flex p-4',
    isImageEmpty ? 'justify-content-center align-items-center gap-3' : 'flex-column justify-content-end'
  )
  const subheaderClassName = clsx(
    appearance?.subheading_text_color === 'blue-400' && 'text-blue-400'
  )

  const stretchedLinkClassNames = clsx(
    'stretched-link text-gray-800 text-decoration-none',
    isImageEmpty && 'd-flex justify-content-center align-items-center gap-3'
  )

  return (
    <div
      className='col-12 col-sm-6 col-md-4 col-lg-3 rounded-3'
      style={{ height: 325 }}
      data-gtm-click-section='bucket'
      data-gtm-click-text={bucketTitle}
    >
      <Card className='border-gray-500-hover overflow-hidden'>
        <div className='d-flex flex-column overflow-hidden' style={{ height: 315 }}>
          {!isImageEmpty && (
            <div className={cardImageClassNames} style={{ maxHeight: 250 }}>
              <ContentstackSimpleImage data={image} className='h-100 object-fit-contain' />
            </div>
          )}
          <div className={cardBodyClasses}>
            <ContentstackLargeShoppingBucketHeader
              data={header}
              subheaderClassName={subheaderClassName}
              link={link}
              linkClassName={stretchedLinkClassNames}
              title={title}
            />
            {!isBodyEmpty && (
              <p className='m-0'>
                <ContentstackJsonRte
                  json={body?.rich_text?.json}
                  excludeOuterTags
                />
              </p>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}

export {
  ContentstackLargeShoppingBucket
}
