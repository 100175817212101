import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if current user is enrolled in Stuller First
 */
function useStullerFirstTargeting (targeting: TargetingMap['STULLER_FIRST']): boolean {
  const auth = useAuth()
  const { authUser } = auth
  const isStullerFirstEnrolled = authUser?.user?.isStullerFirstEnrolled ?? false

  return targeting.targetValue ? isStullerFirstEnrolled : !isStullerFirstEnrolled
}

export { useStullerFirstTargeting }
