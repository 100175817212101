import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check Sterling context
 */
function useSterlingTargeting (targeting: TargetingMap['STERLING']): boolean {
  const auth = useAuth()
  const { isSterling } = auth
  const isSterlingTarget = isSterling ?? false

  return targeting.targetValue ? isSterlingTarget : !isSterlingTarget
}

export { useSterlingTargeting }
