import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if current user is eligible for Stuller First Enrollment
 */
function useStullerFirstEligibleTargeting (targeting: TargetingMap['STULLER_FIRST_ELIGIBLE']): boolean {
  const auth = useAuth()
  const { authUser } = auth
  const isStullerFirstEligible = authUser?.user?.isStullerFirstEligible ?? false

  return targeting.targetValue ? isStullerFirstEligible : !isStullerFirstEligible
}

export { useStullerFirstEligibleTargeting }
