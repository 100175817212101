import type { Targeting } from '@stuller/contentstack/util/types'
import { useGroupTargeting } from './useGroupTargeting'
import { useAuthenticatedTargeting } from './useAuthenticatedTargeting'
import { useShowcaseTargeting } from './useShowcaseTargeting'
import { useShowcaseModeTargeting } from './useShowcaseModeTargeting'
import { useJewelerShowcaseTargeting } from './useJewelerShowcaseTargeting'
import { useQueryStringTargeting } from './useQueryStringTargeting'
import { useBillToAccountTargeting } from './useBillToAccountTargeting'
import { useShipToAccountTargeting } from './useShipToAccountTargeting'
import { useCategoryExcludeTargeting } from './useCategoryExcludeTargeting'
import { useSterlingTargeting } from './useSterlingTargeting'
import { useStullerFirstTargeting } from './useStullerFirstTargeting'
import { useStullerFirstEligibleTargeting } from './useStullerFirstEligibleTargeting'
import { use302MemberTargeting } from './use302MemberTargeting'
import { useEverAndEverMemberTargeting } from './useEverAndEverMemberTargeting'
import { useShowcaseSubdomainTargeting } from './useShowcaseSubdomainTargeting'

const targetHooks = {
  All: useGroupTargeting,
  Any: useGroupTargeting,
  None: useGroupTargeting,
  BillToAccount: useBillToAccountTargeting,
  ShipToAccount: useShipToAccountTargeting,
  Authenticated: useAuthenticatedTargeting,
  CategoryExclude: useCategoryExcludeTargeting,
  Showcase: useShowcaseTargeting,
  ShowcaseMode: useShowcaseModeTargeting,
  ShowcaseSubdomain: useShowcaseSubdomainTargeting,
  JewelerShowcase: useJewelerShowcaseTargeting,
  QueryString: useQueryStringTargeting,
  Sterling: useSterlingTargeting,
  StullerFirst: useStullerFirstTargeting,
  StullerFirstEligible: useStullerFirstEligibleTargeting,
  '302Member': use302MemberTargeting,
  EverAndEverMember: useEverAndEverMemberTargeting
}

export function checkTargeting (targeting: any | Targeting): boolean {
  if (targeting == null) {
    return true
  }

  const typedTargeting = targeting as Targeting

  switch (typedTargeting.targetType) {
    case 'ALL':
      return targetHooks.All(typedTargeting.targetValue, 'ALL')
    case 'ANY':
      return targetHooks.Any(typedTargeting.targetValue, 'ANY')
    case 'NONE':
      return targetHooks.None(typedTargeting.targetValue, 'NONE')
    case 'BILL_TO_ACCOUNT':
      return targetHooks.BillToAccount(typedTargeting)
    case 'SHIP_TO_ACCOUNT':
      return targetHooks.ShipToAccount(typedTargeting)
    case 'AUTHENTICATED':
      return targetHooks.Authenticated(typedTargeting)
    case 'CATEGORY_EXCLUDE':
      return targetHooks.CategoryExclude(typedTargeting)
    case 'SHOWCASE':
      return targetHooks.Showcase(typedTargeting)
    case 'SHOWCASE_MODE':
      return targetHooks.ShowcaseMode(typedTargeting)
    case 'SHOWCASE_SUBDOMAIN':
      return targetHooks.ShowcaseSubdomain(typedTargeting)
    case 'JEWELER_SHOWCASE':
      return targetHooks.JewelerShowcase(typedTargeting)
    case 'QUERY_STRING':
      return targetHooks.QueryString(typedTargeting)
    case 'STERLING':
      return targetHooks.Sterling(typedTargeting)
    case 'STULLER_FIRST':
      return targetHooks.StullerFirst(typedTargeting)
    case 'STULLER_FIRST_ELIGIBLE':
      return targetHooks.StullerFirstEligible(typedTargeting)
    case '302_MEMBER':
      return targetHooks['302Member'](typedTargeting)
    case 'EVER_AND_EVER_MEMBER':
      return targetHooks.EverAndEverMember(typedTargeting)
    default:
      return true
  }
}

/**
 * hook to check a specific target based on its target type
 */
function useContentstackTargeting (targeting: any | Targeting): boolean {
  return checkTargeting(targeting)
}

export { useContentstackTargeting }
