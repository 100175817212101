import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useSearchParams } from 'next/navigation'

/**
 * hook to check the current url for specified query string and value
 */
function useQueryStringTargeting (targeting: TargetingMap['QUERY_STRING']): boolean {
  const searchParams = useSearchParams()

  return searchParams.get(targeting.targetValue.param) === targeting.targetValue.value
}

export { useQueryStringTargeting }
