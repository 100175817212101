import { ContentstackButtonBase } from './ContentstackButtonBase'
import { type ContentstackButtonProps } from './ContentstackButton'
import { type ReactElement } from 'react'

/**
 * Instance of the button that works as a link
 */
function ContentstackButtonLink ({ data, ...otherAttributes }: ContentstackButtonProps): ReactElement {
  const href = data?.link?.url?.href != null ? data.link?.url?.href : ''

  return (
    <ContentstackButtonBase data={data} href={href} {...otherAttributes} />
  )
}

export {
  ContentstackButtonLink
}
