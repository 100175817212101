import { Card, CardBody } from '@stuller/stullercom/ui'
import { type StatisticsCard } from './types'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import clsx from 'clsx'

interface ContentstackStatisticsCardProps {
  data?: StatisticsCard
  theme?: string
}

/**
 * Individual Card rendered inside <ContentstackStatisticsCardGroup /> component instances
 * @memberof ContentstackStatisticsCardGroup
 */
function ContentstackStatisticsCard ({ data, theme }: ContentstackStatisticsCardProps): ReactElement | null {
  const {
    value,
    rich_text: richText,
    icon,
    appearance
  } = data ?? {}

  const cardClassName = clsx(
    'h-100',
    appearance?.text_alignment === 'center' && 'text-center',
    theme === 'dark' ? 'text-white bg-black border-black' : 'bg-gray-100 border-gray-100'
  )

  return (
    <Card className={cardClassName}>
      <CardBody className='p-4'>
        <ContentstackIcon icon={icon} size='3x' className='mb-4' />
        <div className='mb-n3'>
          {!isRteEmpty(value?.json) && (
            <h2 className='display-6'><ContentstackJsonRte excludeOuterTags json={value?.json} /></h2>
          )}
          <ContentstackJsonRte json={richText?.json} />
        </div>
      </CardBody>
    </Card>
  )
}

export {
  ContentstackStatisticsCard
}
