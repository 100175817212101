import { clsx } from 'clsx'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { ProductMultiItemCarousel } from '@stuller/stullercom/ui'
import { type ProductCardCarouselInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { type RelatedItemField } from '@stuller/contentstack/util/types'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackRelatedItems } from '../contentstack-related-items/useContentstackRelatedItems'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'

interface ContentstackProductCardCarouselInnerProps {
  data?: ProductCardCarouselInfoFragment | null
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

function ContentstackProductCardCarouselInner ({
  data
}: ContentstackProductCardCarouselInnerProps): ReactElement | null {
  const {
    body,
    appearance,
    section_header: sectionHeader,
    title
  } = data ?? {}

  // checker classes for conditional rendering
  const emptyBottomButton = isButtonEmpty(body?.button)
  const emptyBottomText = isRteEmpty(body?.rich_text_below_carousel?.json)

  // background-specific classes for background images, colors, and padding
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  const { products, onLoad } = useContentstackRelatedItems({
    relatedItemsField: body?.product_cards as RelatedItemField,
    relatedItemsSource: `featureditems-${title}`
  })
  if (products.length === 0) {
    return null
  }

  const appearanceClassNames = clsx(
    'd-print-none',
    background.className
  ) ?? ''
  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  // bottom rich text and button classes
  const bottomContentAlignmentClassName = (appearance?.button_rich_text_below_carousel_alignment != null && alignmentMap[appearance.button_rich_text_below_carousel_alignment]) ?? 'justify-content-start'
  const bottomTextClassNames = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName,
    sectionHeader?.appearance?.text_color === 'white' && 'text-white'
  )
  const buttonClassNames = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName
  )

  return (
    <section className={appearanceClassNames} id={contentstackTitleToHtmlId(title)}>
      <div className={containerClassNames}>
        <div className='row flex-column flex-md-row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} title={title} />

          <ProductMultiItemCarousel products={products} onLoad={onLoad} />

          {!emptyBottomText && (
            <div className={bottomTextClassNames}>
              <p className='mb-0'>
                <ContentstackJsonRte json={body?.rich_text_below_carousel?.json} excludeOuterTags />
              </p>
            </div>)}

          {!emptyBottomButton && (
            <div className={buttonClassNames}>
              <ContentstackButton data={body?.button} />
            </div>)}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackProductCardCarouselInner
}
