import { Accordion } from '@stuller/stullercom/ui'
import { ContentstackAccordionItem } from './ContentstackAccordionItem'
import { ContentstackButton } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type AccordionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import { useToggleKey } from '@stuller/shared/util/react-hooks'
import clsx from 'clsx'

interface ContentstackAccordionProps {
  data?: AccordionInfoFragment | null
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * The Accordion displays a list of headers that can be clicked to reveal or hide associated content.
 *
 * Accordions can be useful for presenting complex content because they allow users to control what information they see.
 *
 * They can be used to display FAQs.
 */
function ContentstackAccordion ({ data }: ContentstackAccordionProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    accordion_items: accordionItems,
    rich_text_below_accordion: richTextBelowAccordion,
    button,
    appearance,
    targetingConnection
  } = data ?? {}

  const [open, handleToggle] = useToggleKey()

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (sectionHeader == null && accordionItems == null && richTextBelowAccordion == null && button == null) {
    return null
  }

  // Checker classes for conditional rendering
  const hasBottomText = !isRteEmpty(richTextBelowAccordion?.json)
  const hasBottomButton = !isRteEmpty(button?.text?.json)

  // Classes
  const sectionClassName = clsx(
    appearance?.background_color != null && `bg-${data?.appearance?.background_color}`
  )
  const bottomContentAlignment = (richTextBelowAccordion != null && appearance?.button_rich_text_below_accordion_alignment != null) ? appearance.button_rich_text_below_accordion_alignment : 'left'
  const bottomContentAlignmentClassName = alignmentMap[bottomContentAlignment]
  const bottomTextClassNames = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName,
    sectionHeader?.appearance?.text_color === 'white' && 'text-white'
  )
  const buttonClassName = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName
  )

  return (
    <section className={sectionClassName} id={contentstackTitleToHtmlId(title)}>
      <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
        <div className='row flex-column flex-md-row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} title={title} />

          {(accordionItems != null && accordionItems.length > 0) && (
            <Accordion
              data-gtm-click-section='accordion'
              open={open}
              onToggle={handleToggle}
              buttonType={appearance?.icon_style === 'plus-minus' ? 'plus-minus' : 'chevron'}
              faqSchema={appearance?.faqs ?? false}
            >
              {accordionItems.map((accordionItem, index) => (
                <ContentstackAccordionItem
                  data={accordionItem}
                  key={index}
                  index={index}
                  lastItem={index === accordionItems.length - 1}
                  headerColor={data?.appearance?.background_color === 'gray-100' ? 'bg-white' : ''}
                />
              ))}
            </Accordion>)}

          {hasBottomText && (
            <div className={bottomTextClassNames}>
              <p className='mb-0'>
                <ContentstackJsonRte json={richTextBelowAccordion?.json} excludeOuterTags />
              </p>
            </div>)}

          {hasBottomButton && button != null && (
            <div className={buttonClassName} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
              <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
            </div>)}

        </div>
      </div>
    </section>
  )
}

export {
  ContentstackAccordion
}
