import type { LargeShoppingBucketHeader, LargeShoppingBucketLink } from './types'
import { type ReactElement } from 'react'
import { ProductBadge, type ProductBadgeType } from '@stuller/stullercom/ui'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import clsx from 'clsx'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import Link from 'next/link'

interface ContentstackLargeShoppingBucketHeaderProps {
  data: LargeShoppingBucketHeader
  /**
   * Optional class name to determine the color of the subheader
   */
  subheaderClassName?: string
  /**
   * Link data from Contentstack
   */
  link?: LargeShoppingBucketLink
  /**
   * Optional class name for the link
   */
  linkClassName?: string
  /**
   * Optional title for GA tagging
   */
  title?: string | null
}

/**
 * Component for displaying the heading, subheading, and badges in a ContentstackLargeShoppingBucket
 */
function ContentstackLargeShoppingBucketHeader ({
  data,
  subheaderClassName,
  link,
  linkClassName,
  title
}: ContentstackLargeShoppingBucketHeaderProps): ReactElement | null {
  const { heading, subheading, subheading_icon: subheadingIcon, badge } = data ?? {}
  const isHeaderEmpty = isRteEmpty(heading?.json)
  const isSubheadingEmpty = isRteEmpty(subheading?.json)
  const subheaderClassNames = clsx(
    'm-0',
    subheaderClassName
  )

  return (
    <Link
      className={linkClassName}
      href={link?.href ?? ''}
      title={link?.title ?? ''}
      data-gtm-click-subsection1={title}
    >
      {badge != null && (
        <div>
          <ProductBadge type={badge as ProductBadgeType} />
        </div>
      )}
      {!isHeaderEmpty && (
        <h2 className='m-0'>
          <ContentstackJsonRte excludeOuterTags json={heading?.json} />
        </h2>
      )}
      {!isSubheadingEmpty && (
        <h3 className={subheaderClassNames}>
          <ContentstackIcon icon={subheadingIcon} className='me-2' />
          <ContentstackJsonRte excludeOuterTags json={subheading?.json} />
        </h3>
      )}
    </Link>
  )
}

export {
  ContentstackLargeShoppingBucketHeader
}
