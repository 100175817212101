import { type ReactElement, useRef } from 'react'
import { Modal, type ModalProps } from '@stuller/stullercom/ui'
import { type ModalInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackModalFooter } from './ContentstackModalFooter'
import { ContentstackModalBody } from './ContentstackModalBody'
import { ContentstackModalHeader } from './ContentstackModalHeader'

export interface ContentstackModalProps {
  data?: ModalInfoFragment | null
  modalOpen?: boolean
  toggleModalOpen?: () => void
}

/**
 * The Modal is a pop up that shows when something, like a button or image, is clicked to provide additional context or information about the given subject.
 */
function ContentstackModal ({ data, modalOpen, toggleModalOpen }: ContentstackModalProps): ReactElement {
  const portalRef = useRef(typeof window !== 'undefined' ? document.body : undefined)
  const { appearance, system } = data ?? {}

  return (
    <Modal
      id={system?.uid ?? ''}
      isOpen={modalOpen}
      onToggle={toggleModalOpen}
      centered={appearance?.centered !== null ? appearance?.centered as ModalProps['centered'] : undefined}
      fullscreen={appearance?.full_screen !== null ? appearance?.full_screen as ModalProps['fullscreen'] : undefined}
      scrollable={appearance?.scrollable !== null ? appearance?.scrollable as ModalProps['scrollable'] : undefined}
      size={appearance?.size !== null ? appearance?.size as ModalProps['size'] : undefined}
      portalRef={portalRef}
    >
      <ContentstackModalHeader data={data} toggleModalOpen={toggleModalOpen} />
      <ContentstackModalBody data={data} />
      <ContentstackModalFooter data={data} />
    </Modal>
  )
}

export {
  ContentstackModal
}
