import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if website is in Jeweler Showcase mode or not
 */
function useJewelerShowcaseTargeting (targeting: TargetingMap['JEWELER_SHOWCASE']): boolean {
  const auth = useAuth()

  return targeting.targetValue === auth.isJewelerShowcase
}

export { useJewelerShowcaseTargeting }
