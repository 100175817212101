import { type RowColumnInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackYoutubeVideo, isYoutubeVideoEmpty } from '../contentstack-youtube-video/ContentstackYoutubeVideo'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { ContentstackAlert } from '../contentstack-alert/ContentstackAlert'
import { ContentstackLegacyCmsContent } from '../contentstack-legacy-cms-content/ContentstackLegacyCmsContent'
import { ContentstackIconList } from '../contentstack-icon-list/ContentstackIconList'
import { ContentstackBulletList } from '../contentstack-bullet-list/ContentstackBulletList'
import clsx from 'clsx'
import { ContentstackSimpleImageWithLink } from '../contentstack-simple-image-with-link/ContentstackSimpleImageWithLink'

interface ContentstackRowColumnProps {
  data: RowColumnInfoFragment
}

/**
 * The Row Column is used within the Row. They can contain components such as rich text,
 * buttons, images, videos, tables, and buttons with an optional background.
 * @memberof ContentStackRow
 */
function ContentstackRowColumn ({ data }: ContentstackRowColumnProps): ReactElement | null {
  const { appearance, content } = data ?? {}
  const containerClasses = clsx(
    'd-flex flex-column gap-4',
    appearance?.alignment === 'left' && 'align-items-left',
    appearance?.alignment === 'center' && 'align-items-center',
    appearance?.text_color === 'white' && 'text-white'
  )

  if (isArrayEmpty(content)) {
    return null
  }

  return (
    <div className={containerClasses}>
      {content?.map((component, index) => {
        if (component?.__typename === 'RowColumnContentTable' && !isRteEmpty(component?.table?.rich_text_table?.json)) {
          return (
            <div className='mb-n3' key={index}>
              <ContentstackJsonRte json={component?.table?.rich_text_table?.json} tableStyle={component?.table?.appearance?.table_style} />
            </div>
          )
        }
        if (component?.__typename === 'RowColumnContentRichText' && !isRteEmpty(component?.rich_text?.rich_text?.json)) {
          return (
            <div className='mb-n3' key={index}>
              <ContentstackJsonRte json={component?.rich_text?.rich_text?.json} />
            </div>
          )
        } else if (component?.__typename === 'RowColumnContentSimpleImage' && !isSimpleImageEmpty(component?.simple_image)) {
          return (
            <div key={index}>
              <ContentstackSimpleImage data={component?.simple_image} className='img-fluid' />
            </div>
          )
        } else if (component?.__typename === 'RowColumnContentSimpleImageWithLink' && !isSimpleImageEmpty(component?.simple_image_with_link)) {
          return (
            <div key={index}>
              <ContentstackSimpleImageWithLink data={component?.simple_image_with_link} className='img-fluid' />
            </div>
          )
        } else if (component?.__typename === 'RowColumnContentButton' && !isButtonEmpty(component?.button)) {
          return (
            <div key={index}>
              <ContentstackButton data={component?.button} />
            </div>
          )
        } else if (component?.__typename === 'RowColumnContentVideo' && !isYoutubeVideoEmpty(component?.video)) {
          return <ContentstackYoutubeVideo data={component?.video} key={index} />
        } else if (component?.__typename === 'RowColumnContentAlert') {
          return <ContentstackAlert data={component.alert?.alertConnection?.edges?.[0]?.node} key={index} />
        } else if (component?.__typename === 'RowColumnContentLegacyCmsContent') {
          return <ContentstackLegacyCmsContent data={component.legacy_cms_content?.legacy_cms_contentConnection?.edges?.[0]?.node} key={index} />
        } else if (component?.__typename === 'RowColumnContentIconList') {
          return <ContentstackIconList data={component.icon_list?.icon_listConnection?.edges?.[0]?.node} key={index} />
        } else if (component?.__typename === 'RowColumnContentBulletList') {
          return <ContentstackBulletList data={component.bullet_list?.bullet_listConnection?.edges?.[0]?.node} key={index} />
        }

        return null
      })}
    </div>
  )
}

export {
  ContentstackRowColumn
}
