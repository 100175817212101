import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackRowColumn } from './ContentstackRowColumn'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type ReactElement } from 'react'
import { type RowInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackRowProps {
  data: RowInfoFragment | null
}

const rowColumnsOptions: Record<string, string[]> = {
  '1-full': ['col-12'],
  '2-halves': ['col-12 col-md-6'],
  '1-third-1-two-thirds': ['col-12 col-md-4', 'col-12 col-md-8'],
  '1-two-thirds-1-third': ['col-12 col-md-8', 'col-12 col-md-4'],
  '3-thirds': ['col-12 col-md-4']
}

/**
 * The Row contains columns which are flexible for content authors. They can contain components such
 * as rich text, buttons, images, videos, tables, and buttons with an optional background.
 */
function ContentstackRow ({ data }: ContentstackRowProps): ReactElement | null {
  const {
    appearance,
    column_type: columnType,
    section_header: sectionHeader,
    button: topButton,
    button_bottom: bottomButton,
    targetingConnection,
    title
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', appearance?.spacing_y, 'y')

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  if (!showContent || data == null) {
    return null
  }

  const emptyTopButton = isButtonEmpty(topButton)
  const emptyBottomButton = isButtonEmpty(bottomButton)
  const rowColumns = columnType != null ? rowColumnsOptions[columnType] : ['']

  // Keep top button in line with section header
  const topButtonClasses = clsx(
    sectionHeader?.appearance?.text_position === 'text-start' && 'justify-content-start',
    sectionHeader?.appearance?.text_position === 'text-center' && 'justify-content-center',
    sectionHeader?.appearance?.text_position === 'text-end' && 'justify-content-end',
    'col-12 d-flex'
  )
  const bottomButtonClasses = clsx(
    appearance?.button_alignment === 'left' && 'justify-content-start',
    appearance?.button_alignment === 'center' && 'justify-content-center',
    appearance?.button_alignment === 'right' && 'justify-content-end',
    'col-12 d-flex'
  )
  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )

  return (
    <section className={background.className} id={contentstackTitleToHtmlId(title)}>
      <div className={containerClassNames}>
        <div className='row g-5'>
          <ContentstackSectionHeader data={sectionHeader} title={title} />
          {(!emptyTopButton) && (
            <div className={topButtonClasses}>
              <ContentstackButton data={topButton} />
            </div>
          )}
          {data?.columnsConnection?.edges?.map((edge, index) => edge?.node != null && (
            <div className={rowColumns[index % rowColumns.length]} key={index}>
              <ContentstackRowColumn data={edge.node} key={index} />
            </div>
          ))}
          {(!emptyBottomButton) && (
            <div className={bottomButtonClasses}>
              <ContentstackButton data={bottomButton} />
            </div>
          )}
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackRow
}
