import { ContentstackHighlightFeatureMedia } from './ContentsatackHighlightFeatureMedia'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type HighlightFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'

interface ContentstackHighlightFeatureProps {
  data?: HighlightFeatureInfoFragment | null
}

/**
 * Featured media highlights of a catalog or brochure
 */
function ContentstackHighlightFeature ({ data }: ContentstackHighlightFeatureProps): ReactElement | null {
  const {
    title,
    featured_image: featuredImage,
    section_header: sectionHeader,
    media_details: mediaDetails,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  if (featuredImage == null && sectionHeader == null && mediaDetails == null) {
    return null
  }

  const leftColumn = mediaDetails?.filter((_, i) => i % 2 === 0)
  const rightColumn = mediaDetails?.filter((_, i) => i % 2 !== 0)

  return (
    <div className='container-xxxl mw-xxl px-4 py-6 py-md-8' id={contentstackTitleToHtmlId(title)}>
      <div className='row gy-5'>
        <ContentstackSectionHeader data={sectionHeader} title={title} />
        <div className='col-6 col-lg-3 order-2 order-lg-1'>
          <div className='row gy-5'>
            {leftColumn?.map((media, i) =>
              <ContentstackHighlightFeatureMedia data={media} key={i} />
            )}
          </div>
        </div>
        <div className='col-lg-6 order-1 order-lg-2 d-flex d-lg-block justify-content-center'>
          {!isSimpleImageEmpty(featuredImage) &&
            <ContentstackSimpleImage data={featuredImage} />}
        </div>
        <div className='col-6 col-lg-3 order-3'>
          <div className='row gy-5'>
            {rightColumn?.map((media, i) =>
              <ContentstackHighlightFeatureMedia key={i} data={media} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  ContentstackHighlightFeature
}
