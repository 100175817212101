import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if website is in showcase subdomain
 */
function useShowcaseSubdomainTargeting (targeting: TargetingMap['SHOWCASE_SUBDOMAIN']): boolean {
  const { jewelerShowcaseDomainInfo } = useAuth()
  const subdomain = jewelerShowcaseDomainInfo?.subdomain

  return subdomain == null ? false : targeting.targetValue.includes(subdomain)
}

export { useShowcaseSubdomainTargeting }
