import { type ReactElement } from 'react'
import { ContentstackVerticalMediaBoxHeader } from './ContentstackVerticalMediaBoxHeader'
import { ContentstackVerticalMediaBoxMedia } from './ContentstackVerticalMediaBoxMedia'
import { ContentstackVerticalMediaBoxBody } from './ContentstackVerticalMediaBoxBody'
import { type VerticalMediaBox } from './types'
import { useContentstackTargeting } from '../contentstack-targeting/useContentstackTargeting'
import clsx from 'clsx'

interface ContentstackVerticalMediaBoxGroupProps {
  data: VerticalMediaBox
  /**
   * Determines left or center aligned text
   */
  alignmentClass: string
  /**
   * determines column count of Vertical Media Boxes
   */
  columnClass: string | false
}

/**
 * Inidividual Vertical Media Box inside of a ContentstackVerticalMediaGoxGroup
 * @memberof ContentstackVerticalMediaGoxGroup
 */
function ContentstackVerticalMediaBox ({
  data,
  alignmentClass,
  columnClass
}: ContentstackVerticalMediaBoxGroupProps): ReactElement | null {
  const {
    title,
    header,
    body,
    media,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const verticalMediaBoxClasses = clsx(
    alignmentClass,
    columnClass
  )

  if (header == null && body == null && media == null) {
    return null
  }

  return (
    <div className={verticalMediaBoxClasses}>
      <div className='row gy-3'>
        <ContentstackVerticalMediaBoxMedia data={media} />
        <ContentstackVerticalMediaBoxHeader
          alignmentClass={alignmentClass}
          data={header}
        />
        <ContentstackVerticalMediaBoxBody
          alignmentClass={alignmentClass}
          data={body}
          title={title}
        />
      </div>
    </div>
  )
}

export {
  ContentstackVerticalMediaBox
}
