import type { TargetingMap } from '@stuller/contentstack/util/types'
import { useAuth } from '@stuller/stullercom/feat/auth'

/**
 * hook to check if website is in Showcase Mode or not
 */
function useShowcaseModeTargeting (targeting: TargetingMap['SHOWCASE_MODE']): boolean {
  const auth = useAuth()

  return targeting.targetValue === auth.isStullerComShowcaseMode
}

export { useShowcaseModeTargeting }
